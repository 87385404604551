<template>
  <CollectionLayout
    v-if="!openChannelId"
    type="channel"
    :is-loaded="isLoaded"
    :is-collection-loaded="$store.flags.channelCollectionsDataState.loaded"
    :show-error="showError"
  >
    <ChannelCollectionDetails
      v-if="isLoaded && !showError"
      class="container-1440"
      :collection="collection"
    />
  </CollectionLayout>
  <ChannelPage v-else />
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaPage } from 'src/mixins';
import { Watch } from 'vue-property-decorator';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import ChannelCollectionDetails from 'src/components/tv-channels/ChannelCollectionDetails.vue';
import CollectionLayout from 'src/layouts/CollectionLayout.vue';
import { TChannelEnhanced } from 'src/api/channels/types';
import ChannelPage from 'src/pages/ChannelPage.vue';

@Component({
  components: {
    CollectionLayout,
    ChannelCollectionDetails,
    ChannelPage,
  },
})
export default class ChannelCollectionPage extends SequoiaPage {
  @Watch('collection.channels')
  onIsCollectionAvailable(channels: TChannelEnhanced[]) {
    if (!channels.length && process.env.VUE_ENV === 'server') {
      this.$ssrContext.httpStatus = 404;
    }
  }

  get showError() {
    return (
      !this.collection?.channels?.length &&
      !this.$store.flags.tvDataState.loading &&
      this.$store.flags.tvDataState.loaded
    );
  }

  get isLoaded() {
    return (
      this.$store.flags.channelCollectionsDataState.loaded &&
      !this.$store.flags.tvDataState.loading &&
      this.$store.flags.tvDataState.loaded
    );
  }

  get collections() {
    return selectors.channelCollections.channelCollectionsSelector(this.$store);
  }

  get collection() {
    return this.collections.find((collection) => collection.slug === this.collectionSlug);
  }

  get collectionSlug() {
    return this.$route.params.slug;
  }

  get openChannelId() {
    return selectors.tvChannels.openChannelIdSelector(this.$store);
  }

  async serverPrefetch() {
    await actions.tvChannels.loadTvData(this.$store);
    actions.channelCollections.setCurrentCollectionSlug(this.$store, this.collectionSlug);
  }

  async mounted() {
    await actions.tvChannels.loadTvData(this.$store);
    actions.channelCollections.setCurrentCollectionSlug(this.$store, this.collectionSlug);

    // for navigating via history Back <- button
    window.onpopstate = () => {
      actions.channelCollections.setCurrentCollectionSlug(this.$store, this.$route.params.id);
    };
  }

  beforeDestroy() {
    actions.channelCollections.setCurrentCollectionSlug(this.$store, '');
  }

  getMetaInfo() {
    const collectionTitle = this.getByLanguage(this.collection?.title) || '';
    const metaTitle = this.getTranslation('collection_meta_title').replace(
      /%collectionTitle%/g,
      collectionTitle
    );

    return {
      title: metaTitle,
      meta: [
        {
          name: 'description',
          content: this.getByLanguage(this.collection?.description) || '',
        },
        {
          name: 'keywords',
          content: this.collection?.keywords,
        },
        {
          name: 'og:description',
          content: this.getByLanguage(this.collection?.description) || '',
        },
        {
          name: 'og:title',
          content: metaTitle,
        },
        {
          name: 'og:image',
          content: this.getDefaultMetaOgImage(),
        },
      ],
    };
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/side-and-bottom-padding';

.collection-details {
  overflow-x: hidden;

  &::v-deep {
    .with-side-and-bottom-padding {
      @include side-and-bottom-padding;

      &.top {
        margin-top: 0;
      }
    }
  }
}
</style>
