<template>
  <CmsLayout class="collection-layout" :class="theme">
    <main v-if="!isLoaded || (isLoaded && !showError)" class="pb-0" :class="{ loading: !isLoaded }">
      <LoaderSpinner v-if="!isLoaded" />
      <slot />
    </main>
    <div
      v-else-if="showError && !isAnonymous && isLoaded"
      class="error-block container-1440 with-side-padding with-top-and-bottom-padding mt-64"
    >
      <h4 v-html="getTranslation(`${type}_collection_unavailable_error_profile`)" />
      <h4 v-html="getTranslation(`${type}_collection_unavailable_error_default`)" />
    </div>

    <Error404 v-else />
  </CmsLayout>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop, Watch } from 'vue-property-decorator';
import { actions } from 'src/store/actions';
import CmsLayout from 'src/layouts/CmsLayout.vue';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import Error404 from 'src/components/Error404.vue';

@Component({
  components: { Error404, CmsLayout, LoaderSpinner },
})
export default class CollectionLayout extends SequoiaComponent {
  @Prop({ required: true })
  type!: 'channel' | 'vod';

  @Prop({ required: true })
  isLoaded!: boolean;

  @Prop({ required: true })
  isCollectionLoaded!: boolean;

  @Prop({ required: true })
  showError!: boolean;

  @Watch('isCollectionLoaded')
  onIsCollectionLoadedChange(val: boolean) {
    actions.common[val ? 'showFooter' : 'hideFooter'](this.$store);
  }

  async serverPrefetch() {
    await actions.tvChannels.loadChannelsAndGenres(this.$store);
  }

  mounted() {
    this.onIsCollectionLoadedChange(this.isCollectionLoaded);
  }

  destroyed() {
    actions.common.hideFooter(this.$store);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.collection-layout {
  main {
    height: fit-content;
  }

  .loader-spinner {
    flex-grow: 1;
    margin-top: 128px;
  }

  .status-message {
    margin-top: 64px;

    @include tablet {
      margin-top: 48px;
    }

    @include mobile {
      margin-top: 32px;
    }
  }

  &.light {
    main:not(.loading) {
      box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
    }

    &::v-deep .collection-details.container-1440 {
      background-color: var(--c-light-150);
    }
  }

  &.dark {
    main:not(.loading) {
      box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.3);
    }

    &::v-deep .collection-details.container-1440 {
      background-color: var(--c-dark-150);
    }
  }
}
</style>
