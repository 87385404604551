<template>
  <CollectionDetails
    class="channel-collection-details"
    :header="channelCollectionTitle"
    :posters="channelCollectionImages"
    :col-num="7"
    :row-num="7"
    :width-proportion="3"
    :height-proportion="2"
  >
    <TileChannelNow
      v-for="(channel, i) in channelsToShow"
      :key="channel.id + i.toString()"
      :channel="channel"
      :live-program-src="getLiveProgramSrc(channel.id)"
      :live-program-title="getLiveProgramTitle(channel.id)"
      :live-program-start-time="getLiveProgramStartTimeHM(channel.id)"
      :live-program-start-timeline-position="getLiveProgramTimelinePosition(channel.id)"
      :next-program-title="getNextProgramTitle(channel.id)"
      :next-program-starts-in="getNextProgramStartsIn(channel.id)"
      :next-program-start-time="getNextProgramStartTimeHM(channel.id)"
      @showChannelDetails="showChannelDetails"
      @click="startChannel(channel.id)"
    />
    <ButtonDefault
      v-if="isButtonVisible"
      ref="loadMoreTilesButton"
      view="secondary"
      class="show-more mt-32 mt-tablet-16 mt-mobile-16"
      data-cy="show-more-titles"
      size="medium"
      :full-width="true"
      :with-icon="true"
      @click="loadMoreTiles()"
    >
      <IconSVG :svg="IconMoreHorizontal" />
      <span v-html="getTranslation('show_more')" />
    </ButtonDefault>
  </CollectionDetails>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import { selectors } from 'src/store/selectors';
import { actions } from 'src/store/actions';
import { TChannelCollection } from 'src/store/channel-collections/types';
import { getChannelCollectionImages } from 'src/utils/channel-collections';
import { CHANNELS_TO_SHOW_LIMIT } from 'src/constants';
import IconSVG from 'src/components/IconSVG.vue';
import IconMoreHorizontal from 'src/svg/more-horizontal.svg';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import CollectionDetails from 'src/components/CollectionDetails.vue';
import TileChannelNow from 'src/components/tv-channels/TileChannelNow.vue';
import Vue from 'vue';
import Channel from 'src/mixins/Channel';

@Component({
  components: { TileChannelNow, CollectionDetails, ButtonDefault, IconSVG, LoaderSpinner },
})
export default class ChannelCollectionDetails extends Channel {
  IconMoreHorizontal = IconMoreHorizontal;

  channelsToShowOffset = 0;

  @Prop({ required: true })
  collection!: TChannelCollection;

  @Ref('loadMoreTilesButton')
  readonly refloadMoreTilesButton?: Vue;

  get collections() {
    return selectors.channelCollections.channelCollectionsSelector(this.$store);
  }

  get channelsToShow() {
    return (
      this.collection?.channels?.filter(
        (channel, i) => i < CHANNELS_TO_SHOW_LIMIT + this.channelsToShowOffset
      ) || []
    );
  }

  get isButtonVisible() {
    return (
      !!this.collection?.channels &&
      this.collection?.channels.length > this.channelsToShowOffset + CHANNELS_TO_SHOW_LIMIT
    );
  }

  get channelCollectionTitle() {
    return this.getByLanguage(this.collection?.title) || '';
  }

  get channelCollectionImages() {
    return getChannelCollectionImages(this.collection);
  }

  async mounted() {
    await this.loadEpgForChannels();
  }

  async loadEpgForChannels() {
    this.channelsToShow?.forEach((channel) => {
      actions.tvEpg.loadEpgForChannel(this.$store, channel.id, false);
    });
    await actions.appInfo.updateServerTime(this.$store);
  }

  async startChannel(channelId: string) {
    this.gaEvent({
      category: 'channel_collection',
      action: 'channel, start',
      channel_name: channelId,
    });
    await this.$router.push({ path: `/channel-old/${channelId}` }).catch(() => {
      //
    });
  }

  async showChannelDetails(id: string, isEpg: boolean) {
    const type = isEpg ? 'program' : 'about';
    this.gaEvent({ category: 'channel_collection', action: `channel, ${type}`, channel_name: id });
    actions.channelCollections.setCurrentCollectionSlug(this.$store, '');
    await actions.tvChannels.showChannelDetails(this.$store, id, true, isEpg);
  }

  async loadMoreTiles() {
    this.gaEvent({
      category: 'channel_collection',
      action: 'Клик по кнопке "Показать еще"',
      collection_name: this.collection.slug,
    });
    this.channelsToShowOffset += CHANNELS_TO_SHOW_LIMIT;
    await this.loadEpgForChannels();
    (this.refloadMoreTilesButton?.$el as HTMLInputElement)?.blur();
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

@media (min-width: $desktop-s-min) {
  .channel-collection-details {
    &::v-deep {
      .row {
        margin: 0 -16px;
      }
    }
  }
}
</style>
