import { render, staticRenderFns } from "./ChannelCollectionDetails.vue?vue&type=template&id=55a47736&scoped=true&"
import script from "./ChannelCollectionDetails.vue?vue&type=script&lang=ts&"
export * from "./ChannelCollectionDetails.vue?vue&type=script&lang=ts&"
import style0 from "./ChannelCollectionDetails.vue?vue&type=style&index=0&id=55a47736&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55a47736",
  null
  
)

export default component.exports