<template>
  <div class="collection-details" :class="theme">
    <div class="top with-side-and-bottom-padding">
      <div class="info">
        <div v-if="caption" class="caption1" v-html="caption" />
        <h1 v-if="header" class="h2" v-html="header" />
        <div v-if="description" class="body1" v-html="description" />
      </div>
      <BackgroundTiles
        class="background"
        :posters="posters"
        :col-num="colNum"
        :row-num="rowNum"
        :width-proportion="widthProportion"
        :height-proportion="heightProportion"
      />
    </div>

    <div class="bottom row with-side-and-bottom-padding">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import BackgroundTiles from 'src/components/ui/BackgroundTiles.vue';

@Component({
  components: { BackgroundTiles },
})
export default class CollectionDetails extends Vue {
  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop()
  caption?: string;

  @Prop()
  header?: string;

  @Prop()
  description?: string;

  @Prop({ required: true })
  posters!: string[];

  @Prop({ required: true })
  widthProportion!: number;

  @Prop({ required: true })
  heightProportion!: number;

  @Prop({ required: true })
  colNum!: number;

  @Prop({ required: true })
  rowNum!: number;

  @Prop()
  orientation?: 'portrait' | 'landscape';

  get theme() {
    return this.themeForced || this.$store.theme;
  }
}
</script>

<style lang="scss">
@import 'src/styles/common/modal-top-background';
</style>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.collection-details {
  .top {
    position: relative;
    z-index: var(--z-0);
    display: flex;
    min-height: 568px;
    margin-top: -64px;
    overflow: hidden;

    @include desktop-s {
      min-height: 424px;
      margin-top: -56px;
    }

    @include tablet {
      min-height: 404px;
    }

    @include mobile {
      min-height: 268px;
    }

    .background-tiles {
      cursor: default;
    }

    .info {
      position: relative;
      z-index: var(--z-2);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
      margin-top: auto;
      color: var(--c-dark-font-primary);

      .caption1 {
        margin-bottom: 8px;
      }

      h1 {
        margin-bottom: 16px;
      }
    }
  }

  .bottom {
    position: relative;
    z-index: var(--z-1);
    padding-top: 48px;
    overflow-x: hidden;
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------

  &.light {
    .bottom {
      background-color: var(--c-light-100);
    }
  }

  &.dark {
    .bottom {
      background-color: var(--c-dark-150);
    }
  }
}
</style>
