<template>
  <CmsLayout
    ref="channel-page"
    class="channel-page"
    :class="[$store.theme, { 'error-page': !channel }]"
    data-cy="channel-page"
  >
    <template v-slot="{ refScrollWrap }">
      <LoaderSpinner v-if="channelsLoading" />

      <main v-if="!channelsLoading" class="container-1440">
        <ChannelDetails
          v-if="channelId && channel"
          :channel="channel"
          :scroll-block="refScrollWrap"
          class="container-1440"
        />
      </main>

      <ButtonActionScrollTop v-if="!channelsLoading && channel" :scroll-block="refScrollWrap" />

      <Error404 v-if="channelsLoaded && !channel" />
    </template>
  </CmsLayout>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import Channel from 'src/mixins/Channel';
import CmsLayout from 'src/layouts/CmsLayout.vue';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import StatusMessage from 'src/components/StatusMessage.vue';
import ChannelDetails from 'src/components/tv-channels/ChannelDetails.vue';
import ButtonActionScrollTop from 'src/components/ui/buttons/ButtonActionScrollTop.vue';
import { getChannelLogo, getChannelTitle } from 'src/utils/channel';
import logger from 'src/utils/logger';
import { SequoiaPage } from 'src/mixins';
import { makePath } from 'src/utils/url';
import Error404Page from 'src/pages/error/Error404Page.vue';
import Error404 from 'src/components/Error404.vue';

const log = logger(`channel-page`);

@Component({
  components: {
    Error404,
    Error404Page,
    CmsLayout,
    LoaderSpinner,
    StatusMessage,
    ChannelDetails,
    ButtonActionScrollTop,
  },
})
export default class ChannelPage extends mixins(Channel, SequoiaPage) {
  isError = false;

  get channelId() {
    return this.openChannelId || this.$route.params.channelId;
  }

  get channel() {
    return selectors.tvChannels.channelByIdSelector(this.$store, this.channelId);
  }

  get channelFirstGenre() {
    return selectors.tvChannels.channelFirstGenreTitleSelector(this.$store, this.channel);
  }

  get isChannelTabAboutOpen() {
    return selectors.tvChannels.isChannelTabAboutOpenSelector(this.$store);
  }

  get channelPageName() {
    return this.isChannelTabAboutOpen || this.isChannelAboutRoute ? 'about' : 'epg';
  }

  get channelPageMetaTitlePart() {
    return `channel_${this.channelPageName}_meta_`;
  }

  get isChannelAboutRoute() {
    const route = this.$route.name === 'channel-about';
    if (process.env.VUE_ENV === 'client') {
      return route || (window && window.location.href.includes('about'));
    } else {
      return route;
    }
  }

  get isChannelProgramRoute() {
    const route = this.$route.name === 'channel-program';
    if (process.env.VUE_ENV === 'client') {
      return route || (window && window.location.href.includes('program'));
    } else {
      return route;
    }
  }

  get channelsLoaded() {
    return selectors.tvChannels.channelsLoadedSelector(this.$store);
  }

  get channelsLoading() {
    return selectors.tvChannels.channelsLoadingSelector(this.$store);
  }

  async serverPrefetch() {
    await actions.tvChannels.loadChannelsAndGenres(this.$store).catch((err) => {
      log.error(err);
      this.isError = true;
    });
    actions.tvChannels.setIsChannelTabAboutOpen(this.$store, this.isChannelAboutRoute);
    actions.tvChannels.setIsChannelTabEpgOpen(this.$store, this.isChannelProgramRoute);
    this.initMeta();
  }

  async mounted() {
    actions.tvChannels.setModalChannelId(this.$store, this.openChannelId);

    if (!this.channelsLoaded && !this.channelsLoading) {
      await actions.tvChannels.loadChannelsAndGenres(this.$store).catch((err) => {
        log.error(err);
        this.isError = true;
      });
    }

    if (!this.isPageChannelOpen) {
      await this.setIsPageChannelOpen();
    }

    actions.common.showFooter(this.$store);

    window.onpopstate = async () => {
      if (this.isPageChannelOpen && this.$route.params.channelId) {
        await this.setIsPageChannelOpen();
      } else {
        actions.tvChannels.hideChannelDetails(this.$store);
      }
    };
  }

  beforeDestroy() {
    actions.tvChannels.setIsPageChannelOpen(this.$store, false);
    actions.tvChannels.hideChannelDetails(this.$store);
  }

  destroyed() {
    actions.common.hideFooter(this.$store);
  }

  goToMainPage() {
    location.replace(makePath('/'));
  }

  async setIsPageChannelOpen() {
    actions.tvChannels.setIsPageChannelOpen(this.$store, true);
  }

  getMetaInfo() {
    const channelName = getChannelTitle(this.channel) || '';

    const metaTitle = this.getTranslation(`${this.channelPageMetaTitlePart}title`).replace(
      /%channelName%/g,
      channelName
    );

    const metaDescription = this.getTranslation(
      `${this.channelPageMetaTitlePart}description`
    ).replace(/%channelName%/g, channelName);

    const metaKeywords = this.getTranslation(
      `${this.channelPageMetaTitlePart}metaKeywords`
    ).replace(/%channelName%/g, channelName);

    actions.seo.addBreadcrumbs(this.$store, [
      { name: this.getTranslation('breadcrumb_smotreshka'), link: '/' },
      {
        name: getChannelTitle(this.channel),
        link: `/channel/${this.channelPageName}/${this.$route.params.id}`,
      },
    ]);

    actions.seo.addBreadcrumbTelevisionChannel(this.$store, {
      name: getChannelTitle(this.channel),
      image: getChannelLogo(this.channel),
      genre: this.channelFirstGenre,
    });

    actions.seo.addBreadcrumbProduct(this.$store, {
      name: getChannelTitle(this.channel),
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '9.9',
        reviewCount: '73',
        bestRating: '10',
      },
    });

    return {
      title: metaTitle,
      meta: [
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: metaKeywords,
        },
        {
          name: 'og:title',
          content: metaTitle,
        },
        {
          name: 'og:description',
          content: metaDescription,
        },
      ],
    };
  }
}
</script>

<style scoped lang="scss">
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/side-and-bottom-padding';

.channel-page {
  margin-top: 64px;

  .loader-spinner {
    flex-grow: 1;
  }

  .status-message {
    padding-top: 64px;

    @include mobile {
      padding-top: 32px;
    }
  }

  main {
    padding-bottom: 48px;
  }

  .channel-details {
    border-radius: 0;

    &::v-deep {
      .top {
        margin-top: 0;
      }
    }
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------

  &.light {
    main {
      box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
    }

    .container-1440 {
      background-color: var(--c-light-100);
    }
  }

  &.dark {
    main {
      box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.3);
    }

    .container-1440 {
      background-color: var(--c-dark-150);
    }
  }
}
</style>
