<template>
  <div
    ref="backgroundTiles"
    class="background-tiles overlay-accent-after overlay-default-before"
    :class="[$store.theme, size, orientation]"
  >
    <div
      class="bg-diagonal-gradient"
      :style="{
        background: `linear-gradient(to bottom right, ${GRADIENT_VARIATION[0]}, ${GRADIENT_VARIATION[1]})`,
      }"
    />
    <div
      v-if="postersRefilled && !poster"
      class="tilted-posters"
      :style="{
        gridTemplateColumns: `repeat(${colNum}, 1fr)`,
        gridTemplateRows: `repeat(${rowNum}, 1fr)`,
      }"
    >
      <LazyImage
        v-for="(poster, i) in postersRefilled"
        :key="poster + i"
        :with-icon="false"
        :src="poster"
        :width="posterWidth"
        :height="posterHeight"
        tag="div"
        class="image poster"
      />
    </div>
    <LazyImage
      v-if="poster"
      :with-icon="false"
      :src="poster"
      :width="posterWidth"
      :height="posterHeight"
      tag="div"
      class="image bg-poster"
    />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import LazyImage from 'src/components/LazyImage.vue';

@Component({
  components: { LazyImage },
})
export default class BackgroundTiles extends Vue {
  @Prop({ required: true })
  widthProportion!: number;

  @Prop({ required: true })
  heightProportion!: number;

  @Prop()
  posters?: string[];

  @Prop()
  poster?: string;

  // small or big
  @Prop({ default: 'big' })
  size!: 'small' | 'big';

  @Prop({ required: true })
  colNum!: number;

  @Prop({ required: true })
  rowNum!: number;

  GRADIENTS = [
    ['#00b84c', '#0166ff'],
    ['#FFC000', '#DE3344'],
    ['#DE3344', '#7800C2'],
    ['#FFC000', '#00B84C'],
    ['#FFC000', '#7800C2'],
    ['#7800C2', '#66C2FF'],
  ];

  GENERATE_RANDOM_VARIATION_OF_GRADIENTS = Math.floor(Math.random() * 6);
  GRADIENT_VARIATION = this.GRADIENTS[this.GENERATE_RANDOM_VARIATION_OF_GRADIENTS];

  @Ref('backgroundTiles')
  readonly refBackgroundTiles?: HTMLDivElement;

  get numberOfPosters() {
    return this.colNum * this.rowNum;
  }

  get postersRefilled() {
    const len = this.posters?.length;

    if (!this.numberOfPosters || !this.posters || !len || len <= 0) {
      return;
    }

    let postersRefilled = [...this.posters];

    if (len < this.numberOfPosters) {
      let i = 0;
      while (postersRefilled.length !== this.numberOfPosters) {
        postersRefilled.push(postersRefilled[i]);
        i++;
      }
    } else if (len > this.numberOfPosters) {
      postersRefilled = postersRefilled.slice(0, this.numberOfPosters);
    }

    return postersRefilled;
  }

  get aspectRatio() {
    return this.heightProportion / this.widthProportion;
  }

  get orientation() {
    return this.aspectRatio < 1 ? 'landscape' : 'portrait';
  }

  get bigPortraitPosterHeight() {
    return 144 * this.aspectRatio;
  }

  get bigLandscapePosterHeight() {
    return 216 * this.aspectRatio;
  }

  get smallPortraitPosterHeight() {
    return 96 * this.aspectRatio;
  }

  get smallLandscapePosterHeight() {
    return 144 * this.aspectRatio;
  }

  get posterWidth() {
    return this.size === 'big'
      ? this.orientation === 'landscape'
        ? 432
        : 288
      : this.orientation !== 'landscape'
      ? 288
      : 192;
  }

  get posterHeight() {
    return this.size === 'big'
      ? this.orientation === 'landscape'
        ? this.bigLandscapePosterHeight * 2
        : this.bigPortraitPosterHeight * 2
      : this.orientation !== 'landscape'
      ? this.smallLandscapePosterHeight * 2
      : this.smallPortraitPosterHeight * 2;
  }

  @Watch('aspectRatio')
  onAspectRatioChange() {
    if (this.refBackgroundTiles) {
      this.refBackgroundTiles.style.setProperty(
        '--background-tiles-big-portrait-poster-height',
        `${this.bigPortraitPosterHeight}px`
      );
      this.refBackgroundTiles.style.setProperty(
        '--background-tiles-big-landscape-poster-height',
        `${this.bigLandscapePosterHeight}px`
      );
      this.refBackgroundTiles.style.setProperty(
        '--background-tiles-small-portrait-poster-height',
        `${this.smallPortraitPosterHeight}px`
      );
      this.refBackgroundTiles.style.setProperty(
        '--background-tiles-small-landscape-poster-height',
        `${this.smallLandscapePosterHeight}px`
      );
    }
  }

  mounted() {
    this.onAspectRatioChange();
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/overlays';

// stylelint-disable property-no-vendor-prefix

.background-tiles {
  --background-tiles-big-portrait-poster-height: '';
  --background-tiles-big-landscape-poster-height: '';
  --background-tiles-small-portrait-poster-height: '';
  --background-tiles-small-landscape-poster-height: '';

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: var(--c-dark-font-primary);
  cursor: pointer;
  transform: perspective(1000px);

  &.overlay-default-before {
    @include overlay-color-before(var(--c-overlay-lvl-80));
  }

  &.big {
    .poster {
      width: 144px;
      height: var(--background-tiles-big-portrait-poster-height);
    }

    &.landscape {
      .poster {
        width: 216px;
        height: var(--background-tiles-big-landscape-poster-height);
      }
    }
  }

  &.small {
    .poster {
      width: 96px;
      height: var(--background-tiles-small-portrait-poster-height);
    }

    &.landscape {
      .poster {
        width: 144px;
        height: var(--background-tiles-small-landscape-poster-height);
      }
    }
  }

  .bg-diagonal-gradient {
    position: absolute;
    top: 0;
    left: 0;
    z-index: var(--z-rock-bottom);
    width: 100%;
    height: 100%;
  }

  .tilted-posters {
    position: absolute;
    top: 50%;
    left: 50%;
    display: grid;
    transform: translate(-50%, -50%) rotate(-15deg);
    grid-column-gap: 16px;
    grid-row-gap: 16px;

    .poster {
      border-radius: 4px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    }
  }

  .poster,
  .bg-poster {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .bg-poster {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
